import React from "react"
import { Row, Col, Container, Image } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import SiteLogo from "../images/logo.png"

import { FaCode, FaMicrosoft, FaPython, FaPhp } from 'react-icons/fa';

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" keywords={[`gatsby`, `react`, `bootstrap`, `php`, `azure`, `python`]} />
    <Container className="text-center">
      <Row>
        <Col>
          <Image src={SiteLogo} alt="Logo" fluid rounded />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <p>
            Playing around with Azure, Python, Javascript, PHP and any other cool stuff.
          </p>
        </Col>
      </Row>

      <Row>
        <Col>
          <h1>
            <FaCode />{` `}
            <FaMicrosoft />{` `}
            <FaPython />{` `}
            <FaPhp />
          </h1>
        </Col>
      </Row>
      <br /><br /><br />
      <Row>
        <Col>
          <h5>
            {new Date().toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            <br />
            {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}

          </h5>
        </Col>
      </Row>

    </Container>
  </Layout>
)

export default IndexPage
